import React, { useContext, useEffect, useState } from "react";
import {
	Grid,
	FormControl,
	Select,
	MenuItem,
	Button,
	Box,
	Menu,
	ListSubheader,
	Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";
import LocaleContext from "../../../context/LocaleContext";
import {
	getFxcmLogo,
	MenuIcon,
	CloseIcon,
} from "../../../constants/ImageConstants";
import { useDispatch, useSelector } from "react-redux";
import { configurationServices } from "../../../constants/configurationServices";
import { logoutService } from "../../../services/auth.service";
import {
	getCurrentUrlPath,
} from "../../../utils/helpers";
import FXCMPopUp from "../../../components/common/FXCMPopUp";
import FXCMDrawer from "../../common/FXCMDrawer";
import Drawer from "../../Drawer";
import { germanSupport, languagequeryParam, setUserEntity, arabicLanguagequeryParam, additionalProperties } from "../../../reducers/signUpSlice";
import { notifyApiFail } from "../../../reducers/errorNotifySlice";
import { getUserDetails } from "../../../utils/getUsersDetails";
import OpenChat from "../LiveChat/OpenChat";
import { analytics } from "../../../utils/analytics/analytics";
import useDirectionHook from "../../../hooks/useDirectionHook";
import { storelanguageList } from "../../../reducers/commonSlice";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { updateUserInfo } from "../../../reducers/userInfoSlice";
import PropTypes from 'prop-types'

const Header = (props) => { // NOSONAR
	const dispatch = useDispatch();
	const { country } = props;
	const { t: getLabel } = useTranslation();
	const { locale } = useContext(LocaleContext);
	const {
		languageParam,
		authRes,
		userEntity,
		loginRes,
		authToken,
		rbName,
		getUserLocation,
		germanSupportLang,
		authError,
		arabicLanguageParam,
		autoLoginResponse
	} = useSelector((state) => state.signUp);
	const { ip } = useSelector((state) => state.ipAddress);
	const { user } = useSelector((state) => state.user);
	const [languageList, setLanguageList] = useState();
	const navigate = useNavigate();
	const selLang = localStorage.getItem("i18nextLng");
	const [profileName, setProfileName] = useState("");
	const [headerEmail, setHeaderEmail] = useState("");
	const [showSignUp, setShowSignUp] = useState();
	const [showForgotPassword, setShowForgotPassword] = useState();
	const [showLogin, setShowLogin] = useState();
	const [liveChat, setLiveChat] = useState({ width: window.innerWidth });
	const [showForgotUsername, setShowForgotUsername] = useState();
	const [data, setData] = useState("");
	const [languageNotSupport, setLanguageNotSupport] = useState(false);
	const [direction] = useDirectionHook(languageParam) //custom hook
	const [userDetails, setUserDetails] = useState({
		accountNumber: "",
		mt4Server: "",
		isMT4: false,
	});

	const location = useLocation();

	let locationName = location.pathname;
  
	const esmaCondition =
		userEntity === "FXCM_AU" ||
		userEntity === "FXCM_UK" ||
		userEntity === "FXCM_SVG" ||
		(
			userEntity === "FXCM_EU" &&
			(
				locationName === "/rejection" ||
				locationName === "/success" ||
				locationName === "/docUpload" ||
				locationName === "/deposit" ||
				locationName === "/deposit/confirmation" ||
				locationName === "/tax"
			)
		)

	const isDashboardScreen =
	locationName === "/docUpload" ||
	locationName === "/deposit" ||
	locationName === "/deposit/confirmation" ||
	locationName === "/tax"
  
	const localeMenu = userEntity === "FXCM_EU" || userEntity === "FXCM_UK";

	const menuIcon_Button =
		props.screenType !== "login" &&
		props.screenType !== "forgotusername" &&
		props.screenType !== "forgotpassword";

	useEffect(() => {
		window.addEventListener("resize", () => {
			if (setAnchorEl) setAnchorEl(null);
		});
	}, []);

	const isGerman = userEntity === "FXCM_EU" && languageParam === "de-DE" && getUserLocation !== "DE"
	/* istanbul ignore next */
	if(isGerman){
		dispatch(germanSupport(true))
	}

	useEffect(() => {
		if (
			getCurrentUrlPath() === "/"
		) {
			fetchLanguageJson();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEntity, getUserLocation]);
	/* istanbul ignore next */
	useEffect(() => {
		if ( country === "DE" && !germanSupportLang ){
			setLanguageNotSupport(true);
			dispatch(
				notifyApiFail({
					title: getLabel("UNSUPPORTED_LANGUAGAGE"),
					desc: getLabel("ENGLISH_NOT_SUPPORT"),
					type: "warning"
				})
			);
		} else {
			setLanguageNotSupport(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country, languageNotSupport]);
		/* istanbul ignore next */
	const fetchNoticeJson = () => {
		fetch(configurationServices(userEntity).popUpConfigURL)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setData(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		//if (userEntity && userEntity !== "" && userEntity !== "FXCMNO")
			fetchNoticeJson();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setShowSignUp(!authRes && getCurrentUrlPath() !== "/");
		setShowLogin(!authRes && getCurrentUrlPath() !== "/login");
		setShowForgotPassword(
			!authRes && getCurrentUrlPath() !== "/forgotpassword"
		);
		setShowForgotUsername(
			!authRes && getCurrentUrlPath() !== "/forgotusername"
		);
		setHeaderEmail(
			user?.email
				? user.email
				: loginRes?.data?.["user-details"] // NOSONAR
				? loginRes?.data?.["user-details"].email
				: ""
		);
		settingProfileName(
			user?.aboutYourself
				? user?.aboutYourself
				: loginRes?.data?.["user-details"]
		);
	}, [authRes, loginRes, user]);
	/* istanbul ignore next */
	useEffect(() => {
		const requiredLocations = ["/docUpload", "/userInfo", "/deposit", "/tax"];
		if (authToken && requiredLocations?.includes(locationName)) fetchUserDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.authToken]);
		/* istanbul ignore next */
	const fetchUserDetails = async () => {
		const response = await getUserDetails(user?.authToken, "header");
		const responseData = response?.data?.["user-details"];
		setUserDetails({
			...userDetails,
			mt4Server: responseData?.databaseOfficeId?.split(/\s*,\s*/)[0], // NOSONAR
			accountNumber: responseData?.fxowsAccountNumber,
			isMT4: (responseData?.platform === 'MT')
		} );
		dispatch(setUserEntity(responseData?.entity));
		let useinfoData = {
			countryOfResidence: responseData?.countryOfResidenceCode,
			locale: responseData?.userLocale,
			userName : responseData?.username,
		}
		dispatch( updateUserInfo( useinfoData ) );
		dispatch(languagequeryParam(responseData?.userLocale));
	};
	/* istanbul ignore next */
	useEffect(() => {
		if ( autoLoginResponse?.success && loginRes?.data ){
				
			let autoLoginMessage = autoLoginResponse?.data?.["login-response"]?.message;
			if (autoLoginMessage === "Login Success") {
				let usersinfoData = {
					countryOfResidence: loginRes?.data?.["user-details"]?.countryOfResidenceCode,
					email: loginRes?.data?.["user-details"]?.email,
					userName: loginRes?.data?.["user-details"]?.username,
					authToken: loginRes?.data?.["login-response"]?.token,
					locale: loginRes?.data?.["user-details"]?.userLocale,
					fundingStatus: loginRes?.data?.["user-details"]?.userForms?.fundingStatus,
					preCddEligible: loginRes?.data?.["user-details"]?.preCddEligible,
					applicationStatus: loginRes?.data?.["user-details"]?.userForms?.applicationStatus,
					crsStatus: loginRes?.data?.["user-details"]?.userForms?.crsStatus,
					fxowsAccountNumber: loginRes?.data?.["user-details"]?.fxowsAccountNumber,
				};
				dispatch(updateUserInfo(usersinfoData));
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[autoLoginResponse])
		/* istanbul ignore next */
	const fetchLanguageJson = () => {
		fetch(configurationServices(userEntity).languageListURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let finalLanList = [];
				let defaultLanguageCountry=[];
				const newLangaugeList = Object.entries(data).map(([key, value]) => ({
					key,
					value
				}));
				newLangaugeList.forEach((element, key) => {
					if(element?.value?.disableCountry){
						if(!element.value.disableCountry?.find((countryName) => countryName === getUserLocation)) { //NOSONAR
							finalLanList.push(element)
						}
					}else{
						finalLanList.push(element)
					}
					if(element?.value.defaultLanCountry === getUserLocation){
						defaultLanguageCountry.push(element)
						dispatch(languagequeryParam(defaultLanguageCountry[0]?.key));
						
						
					}
				});
				if(newLangaugeList.find((element) => element.key === languageParam)){
					if(finalLanList && !finalLanList.find((item) => item.key === languageParam)){
						dispatch(
							notifyApiFail({
								title: getLabel("LOCALE_UNSUPPORTED_TITLE"),
								desc: getLabel("LOCALE_UNSUPPORTED_SUBTITLE"),
								type: "warning"
							})
						);
					}
					if(arabicLanguageParam && languageParam === "zh-CN"){
						dispatch(
							notifyApiFail({
								title: getLabel("LOCALE_UNSUPPORTED_TITLE"),
								desc: getLabel("LOCALE_UNSUPPORTED_SUBTITLE"),
								type: "arabicWarning"
							})
						);
					}

					
				}
				if(languageParam === "ar-AE"){
					/* istanbul ignore next */
					dispatch(arabicLanguagequeryParam(true))
				}
				setLanguageList(finalLanList);
				dispatch(storelanguageList(finalLanList))
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	/* istanbul ignore next */
	const fetchCommmonLanguageJson = () =>{
		
		fetch(configurationServices(userEntity).commonLanguageListURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				const newLangaugeList = Object.entries(data).map(([key, value]) => ({
					key,
					value
				}));
				setLanguageList(newLangaugeList);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		if (
			getCurrentUrlPath() === "/login" ||
			getCurrentUrlPath() === "/forgotpassword" ||
			getCurrentUrlPath() === "/forgotusername"
		) {
			fetchCommmonLanguageJson();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (languageParam) {
			i18n.changeLanguage(languageParam);
		}
	}, [ languageParam ] );
		/* istanbul ignore next */
	const settingProfileName = (aboutYourself) => {
		let profileName = "";
		if (aboutYourself) {
			const firstName = aboutYourself?.firstName?.replace(
				/^.{1}/g, // NOSONAR
				aboutYourself?.firstName[0]?.toUpperCase()
			);
			const lastName = aboutYourself?.lastName?.replace(
				/^.{1}/g, // NOSONAR
				aboutYourself?.lastName[0]?.toUpperCase()
			);
			if (firstName && lastName) profileName = `${firstName} ${lastName}`;
		}
		setProfileName(profileName);
	};

	useDocumentTitle(getLabel("APP_TITLE"));
	/* istanbul ignore next */
	const onLanguageChange = ( event ) =>{
		const l = event.target.value;
		localStorage.setItem( "PRE_SELECTED_COUNTRY", country );
		if (locale !== l) {
			i18n.changeLanguage(l);
			getFxcmLogo(selLang);
			dispatch(arabicLanguagequeryParam(false))
			dispatch(languagequeryParam(l)); // new
		}
		if(getCurrentUrlPath() === "/login") {
            if(direction.status) {
				navigate("/login")
			}
		}
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean( anchorEl );
		/* istanbul ignore next */
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
  /* istanbul ignore next */
	const handleLogOut = () => {
		if (ip) {
		analytics?.trackEvent("Logged_out", {});
			dispatch(logoutService({ logout: true, ipAddress: ip, logoutRedirectURL: `/login?lang=${languageParam}`, navigate: navigate }));
		}
	};
	/* istanbul ignore next */
	const goToSignupPage = () => {
		dispatch(additionalProperties(loginRes));
		navigate("/");
	};
	const goToLoginPage = () => {
		navigate("/login");
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const handleWindowSizeChange = () => {
		setLiveChat({ width: window.innerWidth });
	};

	const { width } = liveChat;
	const isMobile = width <= 599;
	return (
			<div className="custom-header">
				<div className="container">
					<Grid
						container
						spacing={0}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<div className="logo-FXCM">
								<img src={getFxcmLogo(selLang)} alt="logo" />
							</div>
						</Grid>

						<Grid item className="referring-grid">
							<Box display="flex">
							{authError?.message === "RB not allowed" ? '' :
								(rbName && props.screenType !== "login" && !isMobile ? ( // NOSONAR
									<FormControl className="custom-country-control refering_broker">
										{getLabel("REFERRING_BROKER")} : {rbName}
									</FormControl>
								) : (""))}
								{(!authRes && locationName !== "/resetPassword") && (
									<FormControl className="custom-country-control">
										<Select
											value={languageNotSupport ? "de-DE" : locale}
											onChange={onLanguageChange}
											className="custom-country-dropdown"
											MenuProps={{ className: localeMenu
                                                   ? "custom-dropdown-list-esmaBanner"
                                                   : "custom-dropdown-list" }}
											displayEmpty
											inputProps={{ "aria-label": "Without label" }}
											sx={{
												".MuiSvgIcon-root": {
													background: `url(${
														process.env.REACT_APP_CDN_URL + "/images/Close.svg"
													})`
												},
												".MuiSelect-select": {
													"&::before": {
														background: `url(${
															process.env.REACT_APP_CDN_URL +
															"/images/world-icon.svg"
														})`
													}
												}
											}}
											data-testid="select-language"
										>
											<ListSubheader className="help-text">
												{getLabel("LANGUAGE_HELPTEXT")}
										</ListSubheader>
										
											{/* istanbul ignore next */
												languageNotSupport
												? languageList
														?.filter((item) => item.key.includes("de-DE"))
														.map((item, key) => {
															return (
																<MenuItem key={item.key} value={item.key}>
																	{item.value.nativeName}
																</MenuItem>
															);
														})
												: languageList?.map((item, key) => {
														return (
															<MenuItem key={item.key} value={item.key}>
																{item.value.nativeName}
															</MenuItem>
														);
												  })}
										</Select>
									</FormControl>
								)}
								{showSignUp && showForgotUsername && showForgotPassword && (
									<Button
										variant="text"
										className="button-text"
										onClick={() => goToSignupPage()}
									>
										{getLabel("SIGN_UP")}
									</Button>
								)}
								{showLogin && (
									<Button
										variant="text"
										className={
											rbName && menuIcon_Button
												? "button-text_rb"
												: "button-text"
										}
										onClick={() => goToLoginPage()}
									>
										{getLabel("LOGIN_HEADING1")}
									</Button>
								)}

								{isMobile ? (
									<div className="Profile_Mobile">
										{authRes && <OpenChat />}
										{((rbName && menuIcon_Button) || authRes) && (
											<Button
												id="basic-button"
												aria-controls={open ? "basic-menu1" : undefined}
												aria-haspopup="true"
												aria-expanded={open ? "true" : undefined}
												onClick={handleClick}
												className="menu-class"
											>
												{open ? (
													<img
														src={CloseIcon}
														alt="menuicon"
														className="menu-icon"
													/>
												) : (
													<img
														src={MenuIcon}
														alt="menuicon"
														className="menu-icon"
													/>
												)}
											</Button>
										)}
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											className={
												esmaCondition
                                                     ? direction?.status ? "custom-dropdown-menu-Arabic": "custom-dropdown-menu" // NOSONAR
                                                     : direction?.status ? "custom-dropdown-menu-Arabic-esmabanner" :"custom-dropdown-menu-esmabanner" // NOSONAR
											}
											MenuListProps={{
												"aria-labelledby": "basic-button"
											}}
										>
											<Typography className="name-style">
												{authRes && profileName ? profileName : ""}
											</Typography>
											{authRes && (
												<Typography className="username-style">
													{getLabel("EMAIL")}:{" "}
													{authRes && headerEmail ? headerEmail : ""}
												</Typography>
											)}
											{isDashboardScreen && userDetails?.accountNumber && (
												<Typography className="username-style">
													{getLabel("ACCOUNT_NUMBER")}:{" "}
													{isDashboardScreen && userDetails?.accountNumber}
												</Typography>
											)}
											{isDashboardScreen && userDetails?.mt4Server && userDetails?.isMT4 && (
													<Typography className="username-style">
														{getLabel("MT4_SERVER")}: {isDashboardScreen && userDetails?.mt4Server}
													</Typography>
											 )}
											{rbName ? (
												<div className="refering_broker">
													{getLabel("REFERRING_BROKER")} : {rbName}
												</div>
											) : (
												""
											)}
											{authRes && (
												<FXCMDrawer
													buttontext={getLabel(data.saveANDlogout)}
													className={direction?"save-and-logoutArabic":"save-and-logout"}
													logoutIcon={true}
													content={
														<Drawer
															popUpTitle={getLabel(data.popupTitle)}
															popupSubtitle={getLabel(data.popupSubtitle)}
															handleLogout={handleLogOut}
															logout={getLabel(data.logout)}
															continue={getLabel(data.continue)}
														/>
													}
												/>
											)}
										</Menu>
									</div>
								) : (
									authRes && (
										<div className="Profile_Desktop">
											<FormControl className="custom-dropdown-controlar">
												<Button
													id="basic-button"
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={open ? "true" : undefined}
													onClick={handleClick}
												>
													{authRes && headerEmail ? headerEmail : ""}
												</Button>
												<Menu
													id="basic-menu"
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													className="custom-dropdown-menu Profile_Desktop"
													MenuListProps={{
														"aria-labelledby": "basic-button"
													}}
												>
													<Typography className="name-style">
														{authRes && profileName ? profileName : ""}
													</Typography>
													<Typography className="username-style">
														{getLabel("EMAIL")}:{" "}
														{authRes && headerEmail ? headerEmail : ""}
													</Typography>
													{isDashboardScreen && userDetails?.accountNumber && (
														<Typography className="username-style">
															{getLabel("ACCOUNT_NUMBER")}:{" "}
															{isDashboardScreen && userDetails?.accountNumber}
														</Typography>
													)}
													{isDashboardScreen && userDetails?.mt4Server && userDetails?.isMT4 && (
														<Typography className="username-style">
															{getLabel("MT4_SERVER")}:{" "}
															{isDashboardScreen && userDetails?.mt4Server}
														</Typography>
													 )}
													<FXCMPopUp
													className={direction?"save-and-logoutArabic":"save-and-logout"}
														popUpTitle={getLabel(data.popupTitle)}
														popupSubtitle={getLabel(data.popupSubtitle)}
														buttontext={getLabel(data.saveANDlogout)}
														handleLogout={handleLogOut}
														logout={getLabel(data.logout)}
														continue={getLabel(data.continue)}
														logoutIcon={"logoutIcon"}
													/>
												</Menu>
											</FormControl>
											<OpenChat />
										</div>
									)
								)}
							</Box>
						</Grid>
					</Grid>
				</div>
			</div>
	);
};

Header.propTypes = {
	country: PropTypes.string,
	screenType: PropTypes.string
}

export default Header;
